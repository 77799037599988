<template>
    <div
        :class="{
            clm_loader: PlaqueModule.loading,
        }"
    >
        <transition-group
            v-if="PlaqueModule.loading"
            name="fade"
            mode="out-in"
            class="transition_text"
            tag="div"
        >
            <div
                v-if="showText"
                :key="text"
                class="text"
            >
                {{ text }}
            </div>
        </transition-group>

        <div class="iframe">
            <div class="block_left">
                <div class="number_img_box number_img_box_iframe">
                    <PlaqueImmat />
                </div>

            </div>
            <div class="block_right">
                <div class="box box_registration">
                    <!--<span class="h1">Votre immatriculation</span>-->
                    <p class="text">
                        Tapez votre numéro d’immatriculation ou votre texte
                    </p>
                    <PlateNumberInput />
                </div>

                <div
                    v-if="
                        PlaqueImmat.showRightBand && !PlaqueImmat.showExpiryDate
                    "
                    class="box box_small"
                >
                    <span class="h1">Numéro de département</span>

                    <DepartmentSelect />
                </div>
                <div class="box box_small payer">
                    <div class="overflow_box">
                        <QuantitySelector />
                    </div>
                </div>

                <div class="btn_box">
                    <button
                        class="option btn_violet"
                        @click="goToOptions"
                    >
                        Plus d'options
                    </button>

                    <button
                        class="pay yellow_btn btn desctop_btn radius_btn"
                        @click="pay"
                    >
                        <img
                            alt=""
                            src="@/assets/img/shopping_cart_white.svg"
                            width="20"
                            height="18"
                            loading="lazy"
                        />
                        Ajouter au Panier
                    </button>
                </div>
            </div>

            <!-- Ajout du Carousel -->
            <div
                class="skeepers_carousel_container"
                data-slides-count="2"
            />
        </div>

        <ModalSearch
            v-if="PlaqueModule.showModalSearch"
            :class="{
                show: PlaqueModule.showModalSearch,
            }"
            :action="PlaqueModule.modalSearchAction"
            :background-color="PlaqueModule.modalSearchBackgroundColor"
        />

        <ModalImport
            v-if="PlaqueModule.showModalImport"
            :class="{
                show: PlaqueModule.showModalImport,
            }"
            :image="PlaqueModule.modalImportImage"
            :action="PlaqueModule.modalImportAction"
        />

        <ModalCart
            v-if="PlaqueModule.showModalCart"
            :class="{
                show: PlaqueModule.showModalCart,
            }"
        />

        <ModalIframe
            :is-visible="PlaqueModule.showModalIframe"
            :class="{
                show: PlaqueModule.showModalIframe,
            }"
            @update:is-visible="PlaqueModule.showModalIframe = $event"
        />

        <CustomDialog
            :header-text="PlaqueModule.dialogHeaderText"
            :body-text="PlaqueModule.dialogBodyText"
            :dialog-class="PlaqueModule.dialogClass"
            ok-text="Fermer"
            :is-visible="PlaqueModule.isDialogVisible"
            @update:is-visible="PlaqueModule.isDialogVisible = $event"
        />

        <DialogComponent
            v-if="dialogOptions"
            :title="dialogOptions.title"
            :message="dialogOptions.message"
            :ok-text="dialogOptions.okText"
            :cancel-text="dialogOptions.cancelText"
            :animation="dialogOptions.animation"
            :backdrop-close="dialogOptions.backdropClose"
        />
    </div>
</template>

<script>
    import { useDialogStore } from '@/store/dialogStore'
    import { defineAsyncComponent } from 'vue'
    //import ModalCart from '@/components/ModalCart.vue'
    //import ModalIframe from '@/components/ModalIframe.vue'
    import { mapState } from 'pinia'
    import { useStore } from '@/store'
    //import QuantitySelector from '@/components/QuantitySelector.vue'

    const QuantitySelector = defineAsyncComponent(
        () => import('./components/QuantitySelector.vue')
    )

    const ModalCart = defineAsyncComponent(
        () => import('./components/ModalCart.vue')
    )

    const ModalIframe = defineAsyncComponent(
        () => import('./components/ModalIframe.vue')
    )

    const DialogComponent = defineAsyncComponent(
        () => import('./components/DialogComponent.vue')
    )

    const PlaqueImmat = defineAsyncComponent(
        () => import('./components/PlaqueImmat.vue')
    )

    const ModalSearch = defineAsyncComponent(
        () => import('./components/ModalSearch.vue')
    )
    const ModalImport = defineAsyncComponent(
        () => import('./components/ModalImport.vue')
    )

    const CustomDialog = defineAsyncComponent(
        () => import('./components/CustomDialog.vue')
    )

    const DepartmentSelect = defineAsyncComponent(
        () => import('./components/DepartmentSelect.vue')
    )

    const PlateNumberInput = defineAsyncComponent(
        () => import('./components/PlateNumberInput.vue')
    )

    export default {
        name: 'Iframe',
        components: {
            QuantitySelector,
            DialogComponent,
            ModalCart,
            PlaqueImmat,
            ModalSearch,
            ModalImport,
            CustomDialog,
            DepartmentSelect,
            PlateNumberInput,
            ModalIframe,
        },
        data() {
            return {
                isActive: false,
                texts: [
                    'Enregistrement de vos plaques',
                    'Génération en HD de vos fichiers',
                    'Génération des prévisualisations',
                    'Ajout des produits dans le panier',
                    'Envoi des données au serveur',
                    'Envoi des données au serveur',
                ],
                currentTextIndex: 0,
                duration: 2200,
                showText: false,
                $carousel: false,
                wheel: false,
                scrolling: false,
                showModalSearch: false,
                showModalImport: false,
                importImage: {},
                modalImportAction: '',
                modalSearchAction: '',
                modalSearchBackgroundColor: '',

                dialogStore: useDialogStore(),
            }
        },
        computed: {
            desktop() {
                return window.innerWidth > 1024
            },
            ...mapState(useStore, ['Config', 'PlaqueImmat', 'PlaqueModule']),
            text() {
                return this.texts[this.currentTextIndex]
            },
            dialogOptions() {
                return this.dialogStore.dialogOptions
            },
            quantity: {
                get() {
                    return this.PlaqueImmat.quantity
                },
                set(newValue) {
                    if (newValue >= 1) {
                        this.PlaqueImmat.quantity = newValue
                    }
                },
            },
        },
        watch: {},
        async mounted() {
            this.PlaqueModule.loading = false
            setInterval(() => {
                this.showText = false
                if (this.PlaqueModule.loading) {
                    setTimeout(() => {
                        this.currentTextIndex =
                            (this.currentTextIndex + 1) % this.texts.length
                        //this.showText = true;
                    }, this.duration / 2)
                }
            }, this.duration)

            window.addEventListener('pageshow', (event) => {
                if (event.persisted) {
                    window.location.reload()
                }
            })

            // Injection du script du carousel
            const script = document.createElement('script')
            script.async = true
            script.charset = 'utf-8'
            script.src =
                '//widgets.rr.skeepers.io/carousel/33f114f9-47b4-2974-7daa-029132dde7a0/8aebe9bf-4843-4fb6-b6a1-f74aa523165d.js'
            document.body.appendChild(script)

            //this.approvalSliders();
        },

        methods: {
            pay() {
                this.PlaqueModule.showModalIframe = true
            },
            goToOptions() {
                const productId = this.$route.params.id_product

                window.parent.location.href = productId
                    ? `https://module.plaqueimmat.fr/${productId}`
                    : `https://module.plaqueimmat.fr/`
            },
        },
    }
</script>

<style lang="scss">
    html {
        touch-action: manipulation;
        /*user-select: none;*/
    }

    .text {
        font-size: 20px;
    }

    .iframe {
        position: relative;
        width: 100vw;
        display: flex;
        flex-wrap: wrap;
        min-height: 560px;

        .number_format_box {
            height: 195px;
        }

        .block_left,
        .block_right {
            width: 100%;
        }

        .payer {
            display: flex;
            width: 100%;
            align-content: center;
            align-items: center;
            height: auto;
        }


        .format_info {
            display: block;
        }

    }

    .crisp-client {
        display: none;
    }

    .global_open_list {
        .iframe {
            /*padding-bottom: 260px;*/
        }
    }

    @media screen and (min-width: 1024px) {
        body {
            width: 100%;
        }

        .number_img_box_iframe {
            height: 195px;
            padding: 10px !important;
        }
        .iframe {
            position: relative;
            width: 100vw;
            display: flex;
            max-width: 1024px;
            margin: 0 auto;
            .price_section {
                display: flex !important;
            }
            .block_left,
            .block_right {
                width: 50%;
            }

            .payer {
                width: 100%;
                align-content: center;
                align-items: center;
                height: auto;
            }

        }
    }
</style>
